.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  /*animation-iteration-count: infinite;*/  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

.recycle {
  animation: App-logo-spin infinite 5s linear;
  padding: 5px;
}

.App-header {
  background-color: rgb(243, 243, 243);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-headline-emoji {
  background-color: rgb(243, 243, 243);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(40px + 2vmin);
  color: black;
}

.App-replacement-animations {
  padding-bottom: 15px;
}


.App-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  max-width: 50%;
  color: black;

}

.App-itunes-link {
  display: inline-block;
  overflow: hidden; 
  border-radius: 7px; 
  width: 250px; 
  height: 86px;
}

.Itunes-img {
  border-radius: 7px;
  width: 250px;
  height: 86px;
}

.App-links {
  padding-top: 25px;
}

.App-link {
  color: #185cf0;
  font-size: calc(3px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}
